import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
  open?: boolean
  title: React.ReactNode | string
  children: React.ReactNode | string | string[]
  clickedCallback?: () => void
  isLast?: boolean
  isFirst?: boolean
  className?: string
  fitTitle?: boolean
}

const IconWrapper = styled.div<{ isActive: boolean }>`
  transform: rotate(${(props) => (props.isActive ? '180deg' : '0deg')});
  transition: transform 0.3s;
`

const Section: React.FC<Props> = ({ open, title, children, clickedCallback, className = '' }) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(open || false)

  useEffect(() => {
    if (open !== undefined) {
      setInternalOpen(open)
    }
  }, [open])

  const click = () => {
    if (open !== undefined) {
      clickedCallback && clickedCallback()
    } else {
      setInternalOpen(!internalOpen)
    }
  }

  return (
    <div className={`rounded-sm overflow-hidden h-fit ${className}`}>
      <div className={`flex bg-blue items-center px-2 py-1 cursor-pointer w-full`} onClick={click}>
        <div className="w-10/12 lg:w-11/12 lg:truncate px-2 text-white">{title}</div>
        <div className="w-2/12 lg:w-1/12 flex justify-end">
          <IconWrapper isActive={internalOpen}>
            <ChevronDownIcon className={`w-6 fill-white transition-all`} />
          </IconWrapper>
        </div>
      </div>
      <div className={`content overflow-hidden ${internalOpen ? 'h-fit' : 'h-0'} max-h-fit`}>
        <div className="px-2 py-2">{children}</div>
      </div>
    </div>
  )
}

export default Section
