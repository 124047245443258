import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { Add, Invoke, ResponseCrud } from './types'
import { setLocal } from '../../../redux/action/local'
import { useCallback, useEffect } from 'react'

const useAnyCrud = <T>(prop: string): ResponseCrud<T[]> => {
  const dispatch = useDispatch()
  const anyFeature = crud.use(props[prop])
  const data: T[] = useSelector((s) => s[props[prop]])
  const token = useSelector((s) => s[props.TOKEN])

  const pull: Invoke = useCallback(async () => {
    const data = await anyFeature.list({})
    if (Array.isArray(data)) {
      dispatch(setLocal(props[prop], data))
    }
  }, [anyFeature, dispatch, prop])

  useEffect(() => {
    if (!data && !!token) {
      pull()
    }
  }, [data, token, pull])

  const add: Add = async <T>(item: T) => {
    dispatch(setLocal(props[prop], [...data, item]))
  }

  return [data, pull, add]
}

export default useAnyCrud
