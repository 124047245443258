import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import FileSystem from './FileSystem/FileSystem'
import Settings from './Settings/Settings'
import Timetracking from './Timetracking/Timetracking'

const Dashboard = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN, Level.ADVANCED, Level.USER]} noFooter>
      <Routes>
        {/* default routes */}
        <Route path={'/files'} element={<FileSystem />} />
        <Route path={'/files/:path'} element={<FileSystem />} />
        <Route path={'/settings'} element={<Settings />} />
        <Route path={'/timetracking'} element={<Timetracking />} />

        {/* fallback route */}
        <Route path={'*'} element={<Navigate replace to="/dashboard/files" />} />
      </Routes>
    </Layout>
  )
}

export default Dashboard
