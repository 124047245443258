// import RunInitialization from './RunInitialization/RunInitialization'
import CreateFolder from './CreateFolder/CreateFolder'
import UploadFile from './UploadFile/UploadFile'
import CreateProject from './CreateProject/CreateProject'
import Timetracker from './Timetracker/Timetracker'
import useFileSystem from '../useFileSystem'

const Toolbar = () => {
  const { fileSystem } = useFileSystem()

  return (
    <div className="flex gap-5">
      {fileSystem.isRoot && (
        <>
          <CreateProject />
        </>
      )}
      {!fileSystem.isRoot && (
        <>
          <CreateFolder />
          <UploadFile />
        </>
      )}
      <Timetracker projectUUID={fileSystem.activeProjectUUID} />
      {/* <RunInitialization /> */}
    </div>
  )
}

export default Toolbar
