import DefaultActionButton from '../DefaultActionButton'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import useToast, { Type } from '../../../../hooks/useToast'
import { Timer as TimerIcon } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import HasPermission from '../../../HasPermission/HasPermission'
import useTimetracker from '../../../../hooks/useContext/useTimetracker'
import { TimetrackerEntry } from '../../../../../types/Timetracker'
import ProjectSelector from '../../../../UI/ProjectSelector/ProjectSelector'
import ProjectAreaSelector from '../../../../UI/ProjectAreaSelector/ProjectAreaSelector'
import Textarea from '../../../../UI/Textarea/Textarea'
import Number from '../../../../UI/Input/Number'
import DaySelector from '../../../../UI/DaySelector/DaySelector'
import EntryList from '../../../Timetracking/Project/EntryList'

type Props = {
  projectUUID?: string
}

const Timetracker: React.FC<Props> = ({ projectUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const timetracker = useTimetracker()
  const toast = useToast()
  const [data, setData] = useState<TimetrackerEntry>({ projectUUID, description: '', hours: 0, date: new Date() })
  const [isOpen, setIsOpen] = useState(false)
  const { pull } = useTimetracker()
  const [entries, setEntries] = useState<TimetrackerEntry[]>(null)

  const fetch = useCallback(
    async (projectUUID: string) => {
      if (!!projectUUID) {
        const entries = await pull(projectUUID)
        if (entries) {
          setEntries(entries)
        }
      }
    },
    [pull, setEntries],
  )

  useEffect(() => {
    if (!entries) {
      fetch(data.projectUUID)
    }
  }, [fetch, entries, data.projectUUID])

  const save = async () => {
    const entry = await timetracker.add(data)
    if (entry) {
      setData({ projectUUID: data.projectUUID, description: '', hours: 0, date: new Date() })
      fetch(data.projectUUID)
      toast(t.fileSystem.toolbar.timetracker.success, Type.SUCCESS)
    } else {
      toast(t.fileSystem.toolbar.timetracker.error)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof TimetrackerEntry) => (value: any) => setData({ ...data, [key]: value })

  const changeProjectUUID = (projectUUID: string) => {
    setData({ ...data, projectUUID, area: null })
    fetch(projectUUID)
  }

  if (!data) return null
  return (
    <HasPermission>
      <DefaultActionButton onClick={toggle} Icon={TimerIcon} name={t.fileSystem.toolbar.timetracker.cta} />
      <Modal large onClose={toggle} show={isOpen} onConfirm={save}>
        <div className="text-blue font-bold">{t.fileSystem.toolbar.timetracker.title}</div>
        <div className="flex gap-5">
          <div className="flex-1">
            <div className="flex flex-col gap-3 mt-3">
              <ProjectSelector required value={data.projectUUID} onChange={changeProjectUUID} />
              <ProjectAreaSelector projectUUID={data.projectUUID} value={data.area} onChange={set('area')} />
              <Textarea required label={t.fileSystem.toolbar.timetracker.description} value={data.description} onChange={set('description')} />
              <Number required label={t.fileSystem.toolbar.timetracker.hours} value={data.hours} onChange={set('hours')} />
              <DaySelector required label={t.fileSystem.toolbar.timetracker.date} value={data.date} onChange={set('date')} />
            </div>
          </div>
          <div className="flex-1">
            <div className="max-h-[40vh] overflow-x-hidden overflow-y-auto">
              <EntryList entries={entries} />
            </div>
          </div>
        </div>
      </Modal>
    </HasPermission>
  )
}

export default Timetracker
