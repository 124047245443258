import { useSelector } from 'react-redux'
import { TimetrackerEntry } from '../../../../types/Timetracker'
import props from '../../../../redux/props'
import { getPrettyDate } from '../../../../utility'

const EntryList: React.FC<{ entries?: TimetrackerEntry[] }> = ({ entries }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!entries) {
    return <div className="text-sm text-gray text-center">{t.fileSystem.toolbar.timetracker.entryList.pending}</div>
  } else if (!!entries && entries.length === 0) {
    return <div className="text-sm text-gray text-center">{t.fileSystem.toolbar.timetracker.entryList.empty}</div>
  } else if (!!entries) {
    return (
      <div className="flex h-fit flex-col divide-y divide-lightGray">
        {entries.map((e) => (
          <div key={e.uuid} className="py-1">
            <div className="flex gap-3 justify-between items-center">
              <div className="flex-1 truncate font-bold">{`${e.member.firstname} ${e.member.lastname}`}</div>
              <div className="flex-1 text-sm">{`${t.generic.hourPrefix}${e.hours}`}</div>
              <div className="flex-1 text-end text-sm">{getPrettyDate(e.date)}</div>
            </div>
            {!!e.area && (
              <div className="text-sm">
                {t.fileSystem.toolbar.timetracker.entryList.areaPrefix}
                {e.area}
              </div>
            )}
            <div className="text-sm whitespace-pre-wrap">{e.description}</div>
          </div>
        ))}
      </div>
    )
  } else {
    return null
  }
}

export default EntryList
