import DefaultActionButton from '../DefaultActionButton'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import useToast, { Type } from '../../../../hooks/useToast'
import { Package as PackageIcon } from 'lucide-react'
import useFileSystem from '../../useFileSystem'
import { useState } from 'react'
import Modal from '../../../../UI/Modal/Modal'
import Input from '../../../../UI/Input/Input'
import HasPermission from '../../../HasPermission/HasPermission'
import { Project } from '../../../../../types/Project'
import useProjects from '../../../../hooks/useContext/useProjects'
import Textarea from '../../../../UI/Textarea/Textarea'
import AreaManager from './AreaManager'

const CreateProject = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const createProject = functional.use(Routes.CREATE_PROJECT)
  const toast = useToast()
  const { fileSystem, fetchFiles } = useFileSystem()
  const [data, setData] = useState<Project>({ companyUUID: fileSystem.activeCompanyUUID })
  const [isOpen, setIsOpen] = useState(false)
  const [, , add] = useProjects()

  const run = async () => {
    const project: Project = await createProject(data)
    if (project) {
      setIsOpen(false)
      add(project)
      fetchFiles(fileSystem)
      toast(t.fileSystem.toolbar.createProject.success, Type.SUCCESS)
    } else {
      toast(t.fileSystem.toolbar.createProject.error)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof Project) => (value: any) => setData({ ...data, [key]: value })

  return (
    <HasPermission permission="createFolders">
      <DefaultActionButton onClick={toggle} Icon={PackageIcon} name={t.fileSystem.toolbar.createProject.cta} />
      <Modal onClose={toggle} show={isOpen} onConfirm={run}>
        <div className="text-blue font-bold">{t.fileSystem.toolbar.createProject.title}</div>
        <div className="flex flex-col gap-1 mt-3">
          <Input required label={t.fileSystem.toolbar.createProject.namePlaceholder} value={data.name} onChange={set('name')} />
          <AreaManager value={data.areas} onChange={set('areas')} />
          <Input required type="email" label={t.fileSystem.toolbar.createProject.mailPlaceholder} value={data.mail} onChange={set('mail')} />
          <Textarea
            required
            label={t.fileSystem.toolbar.createProject.mailSignaturePlaceholder}
            value={data.mailSignature}
            onChange={set('mailSignature')}
          />
        </div>
      </Modal>
    </HasPermission>
  )
}

export default CreateProject
