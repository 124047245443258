import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import FullHeightWrapper from '../../UI/FullHeightWrapper/FullHeightWrapper'
import useProjects from '../../hooks/useContext/useProjects'
import Project from './Project/Project'
import { useState } from 'react'
import Input from '../../UI/Input/Input'
import DateRangePicker, { DateRange } from '../../UI/DateRangePicker/DateRangePicker'

const Timetracking = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()
  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState<DateRange>()

  const filter = (projects) => {
    if (search.trim().length === 0) return projects
    return projects.filter((project) => project.name.toLowerCase().includes(search.toLowerCase()))
  }

  return (
    <FullHeightWrapper>
      <Container>
        <div className="flex flex-col gap-1 py-10">
          <div className="flex justify-between">
            <div className="font-bold text-xl text-blue">{t.timetracking.title}</div>
          </div>
          <div className="flex gap-3 mt-3">
            <div className="w-1/3">
              <Input placeholder={t.timetracking.search} value={search} onChange={setSearch} />
            </div>
            <div className="w-1/3">
              <DateRangePicker value={dateRange} onChange={setDateRange} />
            </div>
          </div>
          <div className="flex flex-col gap-10 mt-5">
            {Array.isArray(projects) && filter(projects).map((project) => <Project key={project.uuid} project={project} dateRange={dateRange} />)}
          </div>
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Timetracking
