import useProjects from '../../hooks/useContext/useProjects'
import Dropdown from '../Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type Props = {
  value: string
  onChange: (value: string) => void
  required?: boolean
}

const ProjectSelector: React.FC<Props> = ({ value, onChange, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()

  if (!projects) return null
  return (
    <Dropdown
      label={t.UI.projectSelector.label}
      required={required}
      items={projects.map((c) => ({ label: c.name, value: c.uuid }))}
      value={value}
      onChange={onChange}
    />
  )
}

export default ProjectSelector
