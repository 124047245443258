import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { TimetrackerEntry } from '../../../types/Timetracker'

type Response = {
  pull: (projectUUID: string) => Promise<TimetrackerEntry[]>
  add: (data: TimetrackerEntry) => Promise<boolean>
}

const useTimetracker = (): Response => {
  const createEntry = functional.use(Routes.TIMETRACKER_CREATE_ENTRY)
  const getEntries = functional.use(Routes.TIMETRACKER_GET_ENTRIES)

  const pull = async (projectUUID?: string) => {
    const entries = await getEntries({ projectUUID })
    if (entries) {
      return entries
    }
  }

  const add = async (data: TimetrackerEntry) => {
    const entry = await createEntry(data)
    if (entry) {
      return true
    } else {
      return false
    }
  }

  return {
    pull,
    add,
  }
}

export default useTimetracker
