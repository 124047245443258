import { useEffect } from 'react'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import Input from '../../../../UI/Input/Input'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
}

const AreaManager: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([''])
    }
  }, [value, onChange])

  const update = (i: number) => (v: string) => {
    const newValue = [...value]
    newValue[i] = v
    if (v.length > 0 && i === value.length - 1) {
      onChange([...newValue, ''])
    } else {
      onChange(newValue)
    }
  }

  if (!Array.isArray(value)) return null
  return (
    <div>
      <div className="text-gray text-sm">{t.fileSystem.toolbar.createProject.areas.title}</div>
      <div className="flex flex-col gap-1">
        {value.map((area, i) => (
          <div key={i} className="flex gap-2 items-center">
            <Input value={area} onChange={update(i)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AreaManager
