import { Item as FileSystemItem, ItemType } from '../../../../../types/FileSystem'
import useFileSystem from '../../useFileSystem'
import { ItemWrapper, RowWrapper } from '../Elements'
import useGetFolderOptions from '../ContextMenu/useGetFolderOptions'
import useGetFileOptions from '../ContextMenu/useGetFileOptions'
import { File, Folder } from 'lucide-react'
import prettyBytes from 'pretty-bytes'
import { useSelector } from 'react-redux'
import { getPrettyDateTime } from '../../../../../utility'
import props from '../../../../../redux/props'
import useDownloadFile from '../ContextMenu/hooks/useDownloadFile'
import { useState } from 'react'
import Preview from './Preview/Preview'

type Props = {
  item: FileSystemItem
}

const Item: React.FC<Props> = ({ item }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { changeDirectory } = useFileSystem()
  const getFolderOptions = useGetFolderOptions()
  const getFileOptions = useGetFileOptions()
  const [clickTimeout, setClickTimeout] = useState(null)
  const downloadFile = useDownloadFile()
  const [openPreview, setOpenPreview] = useState(false)

  const getIcon = () => {
    return item.type === ItemType.FOLDER ? <Folder className="w-5 h-5 text-blue-500" /> : <File className="w-5 h-5 text-gray-500" />
  }

  const onClick = () => {
    if (item.type === ItemType.FOLDER) {
      changeDirectory(item)
    } else {
      if (clickTimeout) {
        // If a timeout already exists, clear it and treat this as a double-click
        clearTimeout(clickTimeout)
        setClickTimeout(null)
        handleFileDoubleClick()
      } else {
        // Otherwise, set a timeout and treat it as a single click for now
        const timeout = setTimeout(() => {
          handleFileSingleClick()
          setClickTimeout(null)
        }, 500) // 500ms delay to check for double-click
        setClickTimeout(timeout)
      }
    }
  }

  const handleFileSingleClick = () => {
    // setOpenPreview(true)
  }

  const handleFileDoubleClick = downloadFile(item)

  const getSize = () => {
    if (item.type === ItemType.FOLDER) {
      if (item.size === 1) return `${item.size} ${t.fileSystem.itemTable.folderSizeSuffixSingle}`
      return `${item.size} ${t.fileSystem.itemTable.folderSizeSuffixMultiple}`
    }
    return prettyBytes(item.size)
  }

  const getDate = () => {
    if (item.type === ItemType.FOLDER) return getPrettyDateTime(item.createdOn)
    return getPrettyDateTime(item.updatedDate)
  }

  const getContextOptions = () => {
    if (item.type === ItemType.FOLDER) {
      return getFolderOptions(item)
    } else {
      return getFileOptions(item)
    }
  }
  return (
    <>
      <RowWrapper className="border-b last:border-b-0 hover:bg-gray-50">
        <ItemWrapper contextOptions={getContextOptions()} small={false} className="hover:underline" onClick={onClick} Icon={getIcon()}>
          {item.name}
        </ItemWrapper>
        <ItemWrapper contextOptions={getContextOptions()} className="p-3">
          {getSize()}
        </ItemWrapper>
        <ItemWrapper contextOptions={getContextOptions()} className="p-3">
          {getDate()}
        </ItemWrapper>
      </RowWrapper>
      {openPreview && <Preview item={item} />}
    </>
  )
}

export default Item
