import { useEffect, useState } from 'react'
import ContextMenuOption from './ContextMenuOption'
import { Access } from '../../../../../types/Access'

export type ContextOption = {
  name: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  onClick: () => void
  confirm?: string
  permission?: keyof Access
}

type ContextMenuElementProps = {
  children: React.ReactNode
  options: ContextOption[]
  onClick: () => void
  onDoubleClick: () => void
  className?: string
}

export const ContextMenuElement: React.FC<ContextMenuElementProps> = ({ children, className, onClick, onDoubleClick, options }) => {
  const [contextMenuVisible, setContextMenuVisible] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })

  const handleRightClick = (e) => {
    e.preventDefault()
    setMenuPosition({ x: e.pageX, y: e.pageY })
    setContextMenuVisible(true)
  }

  const onClose = () => {
    setContextMenuVisible(false)
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Close the context menu if clicking outside of it
      if (contextMenuVisible) {
        setContextMenuVisible(false)
      }
    }

    if (contextMenuVisible) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    // Clean up the event listener on component unmount or when menu is closed
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [contextMenuVisible])

  return (
    <div onContextMenu={handleRightClick} className={className} onClick={onClick} onDoubleClick={onDoubleClick}>
      {children}
      {!!contextMenuVisible && (
        <div
          style={{
            top: menuPosition.y,
            left: menuPosition.x,
          }}
          className="rounded-sm z-10 border border-blue shadow-md absolute bg-white divide-y divide-gray-200"
          onClick={(e) => e.stopPropagation()}
        >
          {options.map((option, index) => (
            <ContextMenuOption key={index} option={option} clickCallback={onClose} />
          ))}
        </div>
      )}
    </div>
  )
}

export default ContextMenuElement
