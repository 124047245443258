import Upsert from './Upsert'
import useCompanies from '../../../hooks/useContext/useCompanies'

const CompanyManagement = () => {
  const [companies, pull] = useCompanies()

  return (
    <div>
      <div className="flex justify-end">
        <Upsert pullList={pull} />
      </div>
      {!!companies && (
        <div className="flex flex-col gap-1 mt-3">
          {companies.map((company) => (
            <Upsert key={company.uuid} company={company} pullList={pull} />
          ))}
        </div>
      )}
    </div>
  )
}

export default CompanyManagement
