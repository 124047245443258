import { useEffect, useState } from 'react'
import { Project as ProjectType } from '../../../../types/Project'
import { TimetrackerEntry } from '../../../../types/Timetracker'
import useTimetracker from '../../../hooks/useContext/useTimetracker'
import EntryList from './EntryList'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { formatNumber } from '../../../../utility'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { DateRange } from '../../../UI/DateRangePicker/DateRangePicker'

type Props = {
  project: ProjectType
  dateRange?: DateRange
}

const PropWrapper = styled.div`
  width: calc(100% / 3 - (15px * 2 / 3));
`

const Project: React.FC<Props> = ({ project, dateRange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { pull } = useTimetracker()
  const [baseEntries, setBaseEntries] = useState<TimetrackerEntry[]>()
  const [showEntryList, setShowEntryList] = useState(false)

  useEffect(() => {
    const fetchEntries = async () => {
      console.log('pull')

      const entries = await pull(project.uuid)
      setBaseEntries(entries)
    }

    if (!baseEntries) {
      fetchEntries()
    }
  }, [project.uuid, pull, baseEntries])

  const Prop = ({ label, value }) => {
    return (
      <PropWrapper className="text-sm flex gap-2 items-end">
        <div className="font-bold">{label}</div>
        <div>{value}</div>
      </PropWrapper>
    )
  }

  const filter = (entries: TimetrackerEntry[]) => {
    if (!entries) return []
    if (!dateRange || !dateRange.endDate || !dateRange.startDate) return entries
    return entries.filter((entry) => {
      const entryDate = new Date(entry.date)
      return entryDate >= dateRange.startDate && entryDate <= dateRange.endDate
    })
  }

  const getTotalHours = () => {
    const entries = filter(baseEntries)
    if (!entries) return 0
    return formatNumber(entries.reduce((acc, entry) => acc + entry.hours, 0))
  }

  const getAreaHours = (area: string) => {
    const entries = filter(baseEntries)
    if (!entries) return 0
    return formatNumber(entries.filter((entry) => entry.area === area).reduce((acc, entry) => acc + entry.hours, 0))
  }

  const getEmployeeWithHours = (): { hours: number; name: string }[] => {
    const entries = filter(baseEntries)
    if (!entries) return []
    return Object.values(
      entries.reduce((acc, entry) => {
        if (!acc[entry.memberUUID]) {
          acc[entry.memberUUID] = {
            hours: 0,
            name: `${entry.member.firstname} ${entry.member.lastname}`,
          }
        }

        acc[entry.memberUUID] = {
          ...acc[entry.memberUUID],
          hours: acc[entry.memberUUID].hours + entry.hours,
        }

        return acc
      }, {}),
    )
  }

  const toggleListView = () => setShowEntryList(!showEntryList)

  return (
    <div className="rounded-sm shadow-md bg-white p-3">
      <div className="border-b border-blue font-bold text-lg">{project.name}</div>
      {baseEntries && (
        <div>
          <div className="border-b border-lightGray py-3 flex flex-col gap-2">
            <div>
              <div className="underline text-sm">{t.timetracking.summary.generic}</div>
              <div className="flex gap-[15px]">
                <Prop label={t.timetracking.summary.totalHours} value={getTotalHours()} />
                <Prop label={t.timetracking.summary.totalEntries} value={formatNumber(filter(baseEntries).length)} />
              </div>
            </div>
            {getEmployeeWithHours().length > 0 && (
              <div>
                <div className="underline text-sm">{t.timetracking.summary.byEmployee}</div>
                <div className="flex gap-[15px]">
                  {getEmployeeWithHours().map((e) => (
                    <Prop key={e.name} label={e.name} value={formatNumber(e.hours)} />
                  ))}
                </div>
              </div>
            )}
            {Array.isArray(project.areas) && project.areas.length > 0 && (
              <div>
                <div className="underline text-sm">{t.timetracking.summary.byArea}</div>
                <div className="flex gap-[15px]">
                  {project.areas.map((area) => (
                    <Prop key={area} label={area} value={getAreaHours(area)} />
                  ))}
                </div>
              </div>
            )}
          </div>
          {showEntryList && (
            <div className="hover:underline pt-3 cursor-pointer flex items-center gap-2" onClick={toggleListView}>
              <ChevronUp size={16} />
              {t.timetracking.list.hideEntries}
            </div>
          )}
          {!showEntryList && (
            <div className="hover:underline pt-3 cursor-pointer flex items-center gap-2" onClick={toggleListView}>
              <ChevronDown size={16} />
              {t.timetracking.list.showEntries}
            </div>
          )}
          {showEntryList && (
            <div className="max-h-[40vh] overflow-x-hidden overflow-y-auto mt-3">
              <EntryList entries={filter(baseEntries)} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Project
