type Props = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  name: string
  onClick: () => void
}

const DefaultActionButton: React.FC<Props> = ({ Icon, name, onClick }) => {
  return (
    <div onClick={onClick} className="rounded-sm flex items-center gap-2 bg-white shadow-sm px-3 py-2 cursor-pointer">
      <Icon className="h-4 w-4" />
      {name}
    </div>
  )
}

export default DefaultActionButton
