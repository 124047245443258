import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import { isMoment, Moment } from 'moment'
import Input from '../Input/Input'
import { useEffect } from 'react'

const DaySelector = ({ value, onChange, label = null, required = false }) => {
  useEffect(() => {
    if (!isMoment(value) && typeof value === 'string') {
      const date = new Date(value)
      onChange(date)
    }
  }, [value, onChange])

  const change = (moment: Moment) => {
    if (isMoment(moment)) {
      onChange(moment)
    }
  }

  const getInput = ({ ...props }) => {
    return <Input value={props.value} onChange={props.onChange} {...props} />
  }

  return (
    <Datetime
      dateFormat="DD.MM.YYYY"
      value={value}
      onChange={change}
      inputProps={{ label, required }}
      timeFormat={false}
      closeOnSelect
      input={true}
      renderInput={getInput}
    />
  )
}

export default DaySelector
