import { ChevronRight } from 'lucide-react'
import useFileSystem from '../useFileSystem'
import { Folder, ItemType } from '../../../../types/FileSystem'

type Breadcrumb = {
  name: string
  prefix: string
}

const Breadcrumbs = () => {
  const { fileSystem, changeDirectory } = useFileSystem()

  const getItems = (): Breadcrumb[] => {
    const breadcrumbs = [
      {
        name: 'Home',
        prefix: 'root',
      },
    ]
    const items = fileSystem.activePrefix.split('/')

    items.forEach((item, index) => {
      if (index > 0) {
        const prefix = items.slice(0, index + 1).join('/') + '/'
        breadcrumbs.push({
          name: item,
          prefix,
        })
      }
    })

    return breadcrumbs
  }

  const click = (item: Breadcrumb) => () => {
    const folder: Folder = {
      key: item.prefix,
      name: item.name,
      prefix: item.prefix,
      type: ItemType.FOLDER,
      projectUUID: '',
    }

    changeDirectory(folder)
  }

  return (
    <div className="flex items-center space-x-2 text-sm py-3">
      {getItems().map((item, index) => (
        <div key={index} className="flex items-center space-x-2">
          {index < getItems().length - 2 ? (
            <span className="cursor-pointer hover:underline" onClick={click(item)}>
              {item.name}
            </span>
          ) : (
            <span>{item.name}</span>
          )}
          {index < getItems().length - 2 && <ChevronRight className="w-4 h-4" />}
        </div>
      ))}
    </div>
  )
}

export default Breadcrumbs
